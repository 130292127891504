<template>
    <div class="screen">
        <div
            v-if="isLoading"
            class="spinner spinner__screen spinner--sizex25 spinner--color-lightblack"
        />
        <div
            v-if="photoId"
            class="nav_elements__cross"
            @click="removePhoto"
        />
        <canvas
            ref="canvas"
            class="canvas"
        />
    </div>
</template>

<script>
    import services from '@/services/photo';

    export default {
        props: {
            photo: {
                type: HTMLCanvasElement,
                default: undefined
            },
            index: {
                type: Number,
                default: undefined
            },
            requestId: {
                type: String,
                default: undefined
            },
            ticketName: {
                type: Object,
                default() { return {series: undefined, number: undefined}; }
            },
            unitId: {
                type: String,
                default: undefined
            }
        },
        data() {
            return {
                isLoading: true,
                photoId: undefined
            };
        },
        mounted() {
            const el = this.$refs.canvas;
            let canvas = el.getContext('2d');
            el.width = 640 * 3;
            el.height = 480 * 3;
            el.imageSmoothingEnabled = true;
            el.imageSmoothingQuality  = "high";
            canvas.drawImage(this.photo, 0 ,0);

            let formData = new FormData();
            formData.append('request_id', this.requestId);
            formData.append('ticket_name_series', this.ticketName.series);
            formData.append('ticket_name_number', this.ticketName.number);
            formData.append('unit_id', this.unitId);
            let name_file = `${this.unitId}_${this.ticketName.series}_${this.ticketName.number}_${this.index + 1}.jpeg`;
            el.toBlob(blob => {
                formData.append('images', blob, name_file);
                services.uploadPhoto(formData).then((data) => {
                    this.photoId = data.photo_id;
                    this.$emit('screenNext', true);
                }).catch((error) => {
                    const err = new Error('Не удалось отправить фотографию');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.isLoading = false;
                });
            }, 'image/jpeg', 1.0);
        },
        methods: {
            removePhoto() {
                services.removePhoto(this.photoId).then(() => {
                    this.$emit('removePhoto', this.index);
                }).catch((error) => {
                    const err = new Error(error);
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('showError', { err });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            }
        }
    }
</script>