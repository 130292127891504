import { parseDate, formatDate } from '@/utils/helpers';

export default function(value, template='MM/YY') {
    if (!value) {
        return '';
    }
    if (/^[0-9-]+$/.test(value)) {
        return formatDate(parseDate(value, 'MMYY'), template);
    }
    return formatDate(parseDate(value, 'MMYY'), template);
}
