<template>
    <div class="app__main">
        <header-element>
            <button
                class="button main__submit"
                @click="newSession"
            >
                Начать фотографирование
            </button>
            <button
                class="button button--gold main__submit"
                :disabled="!isScreen"
                @click="next"
            >
                Отправить на сервер
            </button>
        </header-element>
        <main-element
            v-if="resultStatus === 0"
        >
            <template v-slot:h1>
                Сфотографируйте предмет залога <strong class="h1__bold">{{ ticketName.series }}{{ ticketName.number }}</strong>
            </template>
            <div class="main__body">
                <canvas-view
                    @camera="checkCamera"
                />
                <canvas class="canvas__hidde" />
                <div class="gallery__vertical_block">
                    <screen-view
                        v-for="(photo) in reverseItems"
                        :key="photo.index"
                        :index="photo.index"
                        :photo="photo.photo"
                        :request-id="requestId"
                        :ticket-name="ticketName"
                        :unit-id="unitId"
                        @screenNext="screenNext"
                        @removePhoto="removePhoto"
                    />
                </div>
                <div
                    class="gallery__nav"
                >
                    <button
                        class="gallery__button modal__next button button--fianit"
                        @click="takePicture"
                    >
                        Сделать снимок
                    </button>

                    <div class="gallery__comment">
                        <div class="field__tooltip tooltip">
                            Можно использовать пробел
                        </div>
                    </div>
                </div>
            </div>
        </main-element>
        <main-element
            v-else-if="resultStatus === 1"
        >
            <template v-slot:h1>
                Активные сеансы фотографирования не найдены
            </template>
            <div class="main__body">
            </div>
        </main-element>
        <main-element
            v-else-if="resultStatus"
        >
            <template v-slot:h1>
                Обновите сеанс фотографирования
            </template>
            <div class="main__body">
            </div>
        </main-element>
    </div>
</template>

<script>
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';
    import canvasView from '@/views/photo/canvas.vue';
    import screenView from '@/views/photo/screen.vue';
    import services from '@/services/photo';

    export default {
        components: {
            headerElement,
            mainElement,
            canvasView,
            screenView
        },
        props: {
            ticketName: {
                type: Object,
                default() { return {series: undefined, number: undefined}; }
            },
            unitId: {
                type: String,
                default: undefined
            },
            resultStatus: {
                type: Number,
                default: undefined
            },
            requestId: {
                type: String,
                default: undefined
            }
        },
        data() {
            return {
                isScreen: false,
                photos: [],
                camera: false
            };
        },
        computed: {
            reverseItems() {
                return this.photos.slice().reverse();
            }
        },
        mounted() {
            // Отслеживаем клавишу пробела, но не в связке с кнопкой и делаем снимок экрана
            window.addEventListener("keypress", function(e) {
                if(e.keyCode===32 && document.activeElement.tagName !== 'BUTTON') {
                    e.preventDefault();
                    this.takePicture();
                }
            }.bind(this));
        },
        methods: {
            takePicture() {
                navigator.mediaDevices.enumerateDevices().then(
                    function(devices) {
                        devices.forEach(function() {});
                    }
                );

                const canvas = document.querySelector('canvas');
                canvas.width = 640 * 3;
                canvas.height = 480 * 3;
                const ctx = canvas.getContext("2d");
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality  = "high";
                ctx.drawImage(document.querySelector('video'), 0, 0, canvas.width, canvas.height);
                this.photos.push({'photo': canvas, 'index': this.photos.length});
            },
            screenNext(value) {
                this.isScreen = value;
            },
            removePhoto(index) {
                this.photos = this.photos.filter((f) => {return f.index !== index });
                if (!this.photos.length) this.isScreen = false;
            },
            checkCamera(value) {
                this.camera = value;
            },
            newSession() {
                if (this.photos.length > 0){
                    let data = {
                        ticketName: this.ticketName,
                        unitId: this.unitId,
                        requestId: this.requestId,
                    }
                    services.clearPhotos(data).then().catch((error) => {
                        const err = new Error('Не удалось очистить прошлый сеанс, перезагрузите окно браузера');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.photos = [];
						this.$emit('setInitial');
						//this.$router.go();
                        //window.location.href = '/';
                    });
                } else { 
					this.$emit('setInitial');
					//this.$router.go(); 
					/* window.location.href = '/'; */ 
				}
            },
            next() {
                this.$emit('next', true);
            }
        }
    };
</script>
