<template>
    <div class="canvas canvas__block">
        <video
            autoplay
            class="canvas__feed"
        >Ваш браузер не поддерживает тег video.</video>
    </div>
</template>

<script>
    export default {
        created() {
            this.init_camera();
        },
        methods: {
            init_camera() {
                if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                    const width = 640;
                    const height = 480;
                    const factor = 3;
                    let constraints = {
                        video: {
                            width: {
                                min: width,
                                ideal: width * factor,
                                max: width * factor
                            },
                            height: {
                                min: height,
                                ideal: height * factor,
                                max: height * factor
                            },
                        },
                        audio: false,
                    };

                    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                        const videoPlayer = document.querySelector('video');
                        videoPlayer.srcObject = stream;
                        videoPlayer.play();
                        this.$emit('camera', true);
                    }).catch(() => {
                        const err = new Error('Не удалось использовать веб-камеру!');
                        this.$store.dispatch('showError', { err });
                    });
                } else {
                    const err = new Error('mediaDevices: Не удалось использовать веб-камеру!');
                    this.$store.dispatch('showError', { err, vm: this });
                }
            }
        }
    }

</script>