<template>
    <div>
        <photoView
            v-if="isValid"
            :request-id="requestId"
            :ticket-name="ticketName"
            :unit-id="unitId"
            :result-status="resultStatus"
            @setInitial="setInitial"
            @next="next"
        />
        <result
            :is-result="isResult"
        />
    </div>
</template>

<script>
    import photoView from '@/views/photo.vue';
    import result from '@/views/result.vue';
    import photo from '@/services/photo';

    export default {
        components: {
            photoView,
            result
        },
        data() {
            return {
                ticketName: undefined,
                unitId: undefined,
                isValid: true,
                resultOk: false,
                requestId: undefined,
                resultStatus: undefined,
            };
        },
        computed: {
            query() {
                return this.$router.currentRoute.query;
            },
            isResult() {
                return this.resultOk;
            }
        },
        created() {
            this.$store.dispatch('hideError');
            //this.setInitial()
        },
        methods: {
            setInitial() {
                photo.setinitial().then((data) => {
                    this.ticketName = data.ticketName;
                    this.unitId = data.unitID;
                    this.requestId = data.requestID;
                    this.isValid = true;
					this.resultStatus = data.error.status;
                }).catch((error) => {
                    this.$store.dispatch('hideLoader');
                    if (error.status === 99) {
                        const err = new Error(error);
                        this.$store.dispatch('showError', { err });
                    } else {
                        this.isValid = true;
                        this.resultStatus = error.response.data.status;
					}
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            next(value) {
                this.$store.dispatch('showLoader', { label: 'Отправка фотографий на сервер' });
                photo.uploadPhotoToServer(this.requestId).then().catch((error) => {
                    const err = new Error(error);
                    this.$store.dispatch('hideLoader');
                    this.$store.dispatch('showError', { err });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                    this.resultOk = value;
                });
            }
        }
    };
</script>
