import _keys from 'lodash/keys';
import { parse, format } from 'fecha';

function getCacheKey(ns, type, data) {
    let key = ns + ':' + type;
    if (data) {
        key += ':' + JSON.stringify(data, _keys(data).sort());
    }
    return key;
}

function parseDate(value, template) {
    return parse(value, template);
}

function formatDate(value, template) {
    return format(value, template);
}

export {
    getCacheKey,
    parseDate,
    formatDate
};
