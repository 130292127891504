const ajax = {
    timeout: 75000,
    responseType: 'json',
    responseEncoding: 'utf8'
};

const serviceUrl = {
    url: '//photo.web.fianitlombard.ru',
    api: '/api'
};

let urlPath = `${serviceUrl.url}${serviceUrl.api}`;

const callbackUrl = {
    //url: '//fianit-base.flexidev.ru',
    url: '//base.web.fianitlombard.ru',
    localPath: '//localhost',
    port: '8000',
    onLocal: false
};

let callbackBasePath = `${callbackUrl.url}`;

const base = {
    url: `${callbackBasePath}/callback`,
};

const cache = {
    storage: 'sessionStorage'
};

const logger = {
    url: `${urlPath}/logger`,
    level: 'debug',
    token: '76a8f24b971842c21628733e0f0a4068725efba7'
};
const photo = {
    url: `${urlPath}/photo`,
    token: '76a8f24b971842c21628733e0f0a4068725efba7',
};


export {
    ajax,
    cache,
    logger,
    photo,
    base
};