import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
    key: 'fianit-photo',
    storage: window.sessionStorage 
});

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        error: null,
        loader: null,
        requestID: null,
    },
    plugins: [vuexPersist.plugin],
    mutations: {
        error(state, error) {
            state.error = error;
        },
        loader(state, loader) {
            state.loader = loader;
        },
        requestID(state, requestID) {
            state.requestID = requestID;
        }
    },
    actions: {
        saveRequestID(context, RequestID) {
            context.commit('requestID', RequestID);
        },
        showError(context, error) {
            context.commit('error', error);
        },
        hideError(context) {
            context.commit('error', null);
        },
        showLoader(context, loader) {
            context.commit('loader', loader);
        },
        hideLoader(context) {
            context.commit('loader', null);
        }
    }
});
