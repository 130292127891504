import _keys from 'lodash/keys';
import cache from '@/utils/cache';

export default class {
    static install(Vue) {
        Vue.mixin({
            computed: {
                persistId() {
                    return null;
                },
                persistKeys() {
                    return _keys(this._data);
                }
            },
            methods: {
                _persistSaveItem(id) {
                    if (this.persistId) {
                        const key = id + ':' + this.persistId;
                        const data = {};
                        for (let k of this.persistKeys) {
                            data[k] = this[k];
                        }
                        cache.set(key, data);
                        return true;
                    }
                    return false;
                },
                _persistLoadItem(id) {
                    if (this.persistId) {
                        const key = id + ':' + this.persistId;
                        const data = cache.get(key);
                        if (data) {
                            for (let k of this.persistKeys) {
                                this[k] = data[k];
                            }
                            cache.remove(key);
                            return true;
                        }
                    }
                    return false;
                },
                _persistSaveURL(id) {
                    const key = id + ':url';
                    const url = this.$route.fullPath;
                    cache.set(key, url);
                },
                _persistLoadURL(id) {
                    const key = id + ':url';
                    const url = cache.get(key);
                    if (url) {
                        if (url !== this.$route.fullPath) {
                            this.$router.push(url);
                        }
                        cache.remove(key);
                    }
                },
                persistSave(id) {
                    const save = ($el) => {
                        $el._persistSaveItem(id);
                        for (let $child of $el.$children) {
                            save($child);
                        }
                    }

                    save(this.$root);
                    this._persistSaveURL(id);
                },
                persistLoad(id) {
                    const load = ($el) => {
                        $el._persistLoadItem(id);
                        this.$nextTick(() => {
                            for (let $child of $el.$children) {
                                load($child);
                            }
                        });
                    }

                    this._persistLoadURL(id);
                    this.$nextTick(() => {
                        load(this.$root);
                    });
                },
                persistExists(id) {
                    const key = id + ':url';
                    return !!cache.get(key);
                }
            }
        });
    }
}
