<template>
    <modal :is-shown="!!isResult">
        <template v-if="isResult">
            <h3
                slot="header"
                class="modal__header h2"
            >
                Отправка фотографий на сервер запущена
            </h3>
            <div
                slot="content"
                class="modal__content text"
            >
                <p>Фотографирование завершено</p>
                <p>Можно продолжить работу в 1С</p>

                <button
                    class="button button--buttom_right"
                    @click.prevent="handleClose"
                >
                    Закрыть
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
    import modal from '@/components/modal.vue';
    //import { base } from '@/settings';

    export default {
        components: {
            modal
        },
        props: {
            isResult: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleClose() {
                this.$router.go();
            }
        }
    };
</script>
