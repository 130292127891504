import Vue from 'vue';
import VueRouter from 'vue-router';

import index from '@/views/index.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
        meta: { title: route => { return 'Фотографирование | Фианит Ломбард - Рабочее место' } }
    }
];

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'is-subactive',
    linkExactActiveClass: 'is-active',
    routes
});

export default router;
