import { photo as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'photo';
    }
    static checkRequestId(requestID) {
        return this._get(`transaction/${requestID}`, {}).then((data) => {
            return data.result;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { requestID } });
        });
    }
    static setinitial() {
        return this._post(`transaction/set_initial`, {}).then((data) => {
            return data.result;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка получения разрешения на фотографирование', {});
        });
    }
    static uploadPhoto(request) {
        return this._post('file', {}, request, 'multipart/form-data').then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка отправки фотографии на сервер', {});
        });
    }
    static removePhoto(photoId) {
        return this._delete(`file/${photoId}`, {}, {}, 'text/plain').then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Удаление фотографии с сервера', { request: { photoId } });
        });
    }
    static clearPhotos(data) {
        return this._delete(`file/destroy_all_photos`, {}, data, 'text/plain').then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Удаление фотографии с сервера', { request: { data } });
        });
    }
    static uploadPhotoToServer(requestID) {
        return this._get(`uploading/${requestID}`, {}).then((data) => {
            return data.result;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { requestID } });
        });
    }
}
